import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import(/* webpackChunkName: "login_home_welcome" */ '@/pages/Login.vue')
const Home = () => import(/* webpackChunkName: "login_home_welcome" */ '@/pages/Home.vue')
const fanEconomyone   = () => import(/* webpackChunkName: "six_fan_welcome" */ '../components/fanEconomyone.vue')
const fanEconomytwo   = () => import(/* webpackChunkName: "six_fan_welcome" */ '../components/fanEconomytwo.vue')
const fanEconomythree   = () => import(/* webpackChunkName: "six_fan_welcome" */ '../common/shop.vue')
const fanEconomyExport   = () => import(/* webpackChunkName: "six_fan_welcome" */ '../components/fanEconomyExport.vue')
const tb_order   = () => import(/* webpackChunkName: "six_fan_welcome" */ '../components/tb_order.vue')





Vue.use(VueRouter)
// 隐藏重复路由跳转的报错问题
const originalPush = VueRouter.prototype.push
// 覆写了, 原本的push方法, 将报错捕获
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes: [
    {
      path: '/home',
      component: Home,
      children: [
        { path: '/home', component: fanEconomyone, name: 'fan-economyone' },
        { path: '/fan-economyone', component: fanEconomyone, name: 'fan-economyone'},
        { path: '/fan-economytwo', component: fanEconomytwo, name: 'fan-economytwo'},
		{ path: '/fanEconomythree', component: fanEconomythree, name: 'fanEconomythree'},
        { path: '/fan-economyExport', component: fanEconomyExport, name: 'fan-economyExport'},
		{ path: '/tb_order', component: tb_order, name: 'tb_order'},
      ]
    },
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
  ]
})
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  // to.path 要去的路径
  if (to.path === '/login'|| token) {
    next()
  } else {
    // 去的是其他页面, 且没有token, 拦截到登录页
    router.push('/login')
  }
})
export default router

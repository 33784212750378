<template>
  <div>

      <el-table
      :data="whiteTable"
      border
      fit
      style="width: 100%">
      <el-table-column prop="id" label="ID" ></el-table-column>
      <el-table-column prop="orderID" label="订单号" ></el-table-column>
      <el-table-column prop="created" label="提交时间" ></el-table-column>
      <el-table-column prop="nickname" label="昵称" ></el-table-column>
      <el-table-column  label="会员头像" >
        <template v-slot={row}>
          <img style="width:50px;" :src="row.headimgurl">
        </template>
      </el-table-column>
      <el-table-column prop="type" label="支付方式" ></el-table-column>
      <el-table-column prop="zfb_name" label="支付宝名"  ></el-table-column>
      <el-table-column prop="zfb_number" label="支付宝账户"  ></el-table-column>
      <el-table-column prop="money" label="支付金额" ></el-table-column>
      <el-table-column  label="操作"  v-if="tabsIndex==0">
        <template v-slot={row}>
          <el-button size="mini" round type="success" @click="tapBtnFn(row.id,'已付款',row.wxrecipient_id)">已付款</el-button>
          <el-button size="mini" round type="danger" @click="tapBtnFn(row.id,'已驳回',row.wxrecipient_id)">已驳回</el-button>
        </template>
      </el-table-column>
      </el-table>
    <el-pagination
     background
     v-if="ishowpagination"
    @current-change="handleCurrentChange1"
     layout="prev, pager, next"
     :total="count3">
   </el-pagination>
  </div>
</template>

<script>
export default {
  props:['whiteTable','count3','type','tabsIndex'],
    data(){
      return{
          pageIndex:1,
          ishowpagination:false,
           isshowprevie:false,
      }
    },
watch:{
whiteTable(newval){
 if(newval.length==0){
       this.ishowpagination=false
    }else{
      this.ishowpagination=true
    }
}
},
    methods:{
          tapprevie(){
     this.isshowprevie=false
    },
      tapBtnFn(id,red_state,wxrecipient_id){
        console.log(id,red_state)
        let message= ''
        if(red_state=='已付款'){
            message= '确定要操作已付款吗'
        }else{
            message= '确定要进行驳回吗'
        }
        this.$parent.confirmFn(id,red_state,message,wxrecipient_id)
      },
      handleCurrentChange1(blackpage){
                this.pageIndex=blackpage
                this.$emit('CurrentChangeFn',blackpage)
     }
    }
}
</script>

<style lang="scss" scoped>
.el-button{
  margin: 5px auto;
}

</style>
<template>
	<div id="app">
		<router-view></router-view>
		<p class="footer-zj"><a href="https://beian.miit.gov.cn" target="_blank">Copyright © 2022-2023 河南鑫鼎佩网络科技有限公司 豫ICP备20003293号-2</a></p>
	</div>
</template>

<script>
export default {};
</script>

<style>
html,
body,
#app {
	height: 100%;
}
.footer-zj {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	color: #fff;
	margin: 0;
	text-align: center;
}
.footer-zj a {
	text-decoration: none;
	color: inherit;
}

.footer-zj a:link {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	/*禁止链接高亮*/
	-webkit-touch-callout: none;
	/*禁止链接长按弹出选项菜单*/
}

.footer-zj a:link,
.footer-zj a:visited,
.footer-zj a:hover,
.footer-zj a:active {
	color: #000;
	text-decoration: none;
}
</style>

import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue'
import router from './router'
import moment from 'moment'
import md5 from 'js-md5'
// import './style/base.scss'
// import './style/icon/iconfont.css'
import PageHeader from './common/PageHeader.vue'
import bottonThreeTable from './common/bottonThreeTable.vue'
import FourTable from './common/FourTable.vue'
import Tabs from './common/tab.vue'
import axios from 'axios'
import qs from 'qs'
Vue.use(ElementUI);

Vue.component('Tabs',Tabs)
Vue.component('PageHeader',PageHeader)
Vue.component('FourTable',FourTable)
Vue.component('bottonThreeTable',bottonThreeTable)
Vue.config.productionTip = false
Vue.prototype.$moment = moment;
Vue.prototype.$md5 = md5;
//axios.defaults.baseURL = 'http://127.0.0.1:118'
axios.defaults.baseURL = 'https://www.xindingpei.com'

Vue.prototype.$axios = axios
Vue.filter('fixUrl', value => {
  if (value.startsWith('http')) {
    return value
  } else {
    return axios.defaults.baseURL + value
  }
})
Vue.filter('imgUrl', value => {
  if (value.startsWith('http')) {
    return value
  } else {
    // return 'https://yd.123comeon.com/public' + value
    return '' + value
  }
})
Vue.filter('redType', value => {
    switch (value) {
      case '1':
        return '普通红包'
        break;
      case '2':
        return '返利红包'
        break;
      case '3':
        return '随机红包'
        break; 
      case '4':
        return '抽奖红包'
        break;  
        case '5':
          return '佣金红包'
          break;                                 
    }
})
Vue.filter('time', value => {
  return moment(value).format('YYYY-MM-DD HH:mm:ss')
})
// 响应拦截
axios.interceptors.response.use(function (response) {
  if (response.data.status == 401) {
    localStorage.removeItem('token')
    localStorage.removeItem('phone')
    router.push('/login')
  }
  //NProgress.done()
  return response
}, function (error) {
  return Promise.reject(error)
})
// 设置拦截器
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = token
  }
  if(config.method=='post'){
    config.data=qs.stringify(config.data)
  }
  //NProgress.start()
  return config
}, function (error) {
  return Promise.reject(error)
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="PageHeader">
      <div class="lineone"></div>
      <div class="text"><slot></slot></div>
      <div @click="tapright"><slot name="right"></slot></div>
      <div class="lineteo" ></div>
  </div>
</template>

<script>
export default {
    methods:{
        tapright(){
        console.log('下载')
			//window.location.href = 'https://yd.123comeon.com/public/index.php/klhb/excel_temact'
        }
    }
}
</script>

<style lang="scss" scoped>
.PageHeader{
    display: flex;
    align-items: center;
    .lineone{
        border: 1px solid#e6e6e6;
        width: 30px;
        height: 1px;
    }
     .lineteo{
        border: 1px solid #e6e6e6;
        width: 100%;
        height: 1px;
     }
    .text{
        white-space: nowrap;
        padding: 0 20px;
        font-size: 20px;
    }
}
</style>
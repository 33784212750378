<template>
  <div class="tabs">
      <div class="tabs_list" v-for="(item,index) in tabs" :class="{active:activeNum==index}" :key="index" @click="tapTabs(index)">{{item}}</div>
  </div>
</template>

<script>
export default {
   props:{
           tabs:Array
       },
   data(){
       return{
           activeNum:0,
         
       }
   },
   methods:{
       tapTabs(index){
          this.activeNum=index
          this.$emit('taptabs',index)
       }
   }
}
</script>

<style lang="scss" scoped>
 .tabs{
     display: flex;
     .tabs_list{
       flex: 1;
       display: flex;
       justify-content: center;
       align-items: center;
       height: 40px;
       border-bottom: 1px solid transparent;
     }
     .active{
          border-bottom: 1px solid #FF3030;
          color: #FF3030;
     }
 }
</style>